!<template>
  <div class="notContainer">暂不支持该浏览器，请下载最新版本(Edge,Firefox,Chrome,Safari,Opera)浏览器</div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  methods: {},
  created() {},
}
</script>

<style lang='less' scoped>
.notContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 60px;
  font-weight: 800;
}
</style>
